import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import {FaFacebookF, FaGithub, FaPinterestP, FaTwitter, FaYoutube} from 'react-icons/fa';
import 'tachyons';


export default () => (
    <StaticQuery
        query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            mailChimpUrl
            pinterest
            facebook
            twitter
            youtube
            github
          }
        }
      } 
    `}
        render={data => (
            <footer className="pa2 bg-dark-gray near-white pv5">
                <div className="flex flex-wrap justify-around w-100 mw9 center mb5">
                    <div className="w-100 mw5 mb4">
                        <span className="display f2">{data.site.siteMetadata.siteTitle}</span>
                        <a target='_blank' href='https://www.google.com/maps/place/Fusion+Brow+Bar/@41.1163186,-74.1536084,15z/data=!4m2!3m1!1s0x0:0xb7a14519f6cc4189?sa=X&ved=2ahUKEwigzfyFg7DtAhWPEFkFHX3BAR8Q_BIwCnoECBIQBQ'>
                            <div className="w-100 mw9 silver center sans-serif f6">
                                <p>24 Lafayette Avenue</p>
                                <p>Suffern, NY 10901</p>

                            </div>
                        </a>
                        <hr/>
                        <div className="w-100 flex justify-around items-center pv2">
                            {data.site.siteMetadata.facebook && (
                                <a
                                    className="near-white"
                                    href={data.site.siteMetadata.facebook}
                                >
                                    <FaFacebookF/>
                                </a>
                            )}

                            {data.site.siteMetadata.youtube && (
                                <a
                                    className="near-white"
                                    href={data.site.siteMetadata.youtube}
                                >
                                    <FaYoutube/>
                                </a>
                            )}

                            {data.site.siteMetadata.github && (
                                <a
                                    className="near-white"
                                    href={data.site.siteMetadata.github}
                                >
                                    <FaGithub/>
                                </a>
                            )}

                            {data.site.siteMetadata.pinterest && (
                                <a
                                    className="near-white"
                                    href={data.site.siteMetadata.pinterest}
                                >
                                    <FaPinterestP/>
                                </a>
                            )}

                            {data.site.siteMetadata.twitter && (
                                <a className="near-white" href={data.site.siteMetadata.twitter}>
                                    <FaTwitter/>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-column">
                        <span className="near-white sans-serif f3 tracked mb3 db">Hours</span>
                        <table>
                            <tr>
                                <th>
                                    Saturday
                                </th>
                                <th>
                                    10AM–5:30PM
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    Sunday
                                </th>
                                <th>10AM–5:30PM</th>
                            </tr>
                            <tr>
                                <th>Monday</th>
                                <th>Closed</th>
                            </tr>
                            <tr>

                                <th>Tuesday</th>
                                <th>10AM–5:30PM</th>
                            </tr>
                            <tr>

                                <th>Wednesday</th>
                                <th>
                                    10AM–5:30PM
                                </th>


                            </tr>
                            <tr>


                                <th>Thursday</th>
                                <th>
                                    10AM–5:30PM
                                </th>
                            </tr>
                            <tr>


                                <th>Friday</th>
                                <th>
                                    10AM–5:30PM
                                </th>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-column">
                        <span
                            className="near-white sans-serif f3 tracked mb3 db">Explore</span>
                        <Link to="/about" className="near-white sans-serif f5 tracked pv1 db">ABOUT US</Link>
                        <Link to="/services" className="near-white sans-serif f5 tracked pv1 db">SERVICES</Link>

                        <a href={data.site.siteMetadata.mailChimpUrl}
                           className="near-white sans-serif f5 tracked pv1 db">NEWS LETTER</a>
                    </div>
                </div>
                {/*<div className="w-100 mw9 center silver mb3">*/}
                {/*    <div className="w-100 bb b--mid-gray mv3"></div>*/}
                {/*</div>*/}
                {/*<div className="w-100 mw9 silver center sans-serif f5">*/}
                {/*    <p>Fusion Brow Bar (c) 2020</p>*/}
                {/*</div>*/}
            </footer>
        )}/>
)
